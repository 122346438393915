import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { noOp } from '@neslotech/utils';

import { USER_AVATAR_ALT } from '../../../tool/constant';
import { PROFILE_TYPE } from '../../../tool/prop-types';

import NotificationsProvider from '../../../provider/Notifications.provider';

import { useAuth } from '../../../hook/useAuth';
import { useDevice } from '../../../hook/useDevice';
import { useNotifications } from '../../../hook/useNotifications';

import Notifications from '../../../component/notification/Notifications';

import { ReactComponent as BellIconUnread } from '../../../icon/bell-icon-unread.svg';
import { ReactComponent as BellIcon } from '../../../icon/bell-icon.svg';
import { ReactComponent as BurgerMenuIcon } from '../../../icon/burger-menu-icon.svg';
import { ReactComponent as Logo } from '../../../icon/logo.svg';
import { ReactComponent as NesloIcon } from '../../../icon/neslo-mobile-icon.svg';
import { ReactComponent as SearchIcon } from '../../../icon/search-icon.svg';

import { Button } from '../button/Button';
import { Dropdown } from '../dropdown/Dropdown';

import './nav-bar.scss';

const BellNotificationIcon = ({ onClick }) => {
  const { hasUnreadNotifications } = useNotifications();

  return (
    <div className="nav-bar__notification" onClick={onClick} onKeyDown={noOp}>
      {hasUnreadNotifications ? <BellIconUnread /> : <BellIcon />}
    </div>
  );
};

const NotificationDropdown = () => {
  const { mobile } = useDevice();
  const navigate = useNavigate();

  const [closed, setClosed] = useState();

  return (
    <>
      {!mobile && (
        <Dropdown
          closed={closed}
          dropdownChildren={<Notifications setClosed={setClosed} />}
          placement="bottom-end"
          offset={mobile ? [0, 5] : [0, 0]}
        >
          <BellNotificationIcon onClick={() => setClosed(false)} />
        </Dropdown>
      )}
      {mobile && <BellNotificationIcon onClick={() => navigate('/notifications')} />}
    </>
  );
};

const MenuButton = ({ onMenuOpen }) => {
  const { mobile } = useDevice();

  if (mobile) {
    return (
      <div className="nav-bar__menu-button-wrapper">
        <button onClick={onMenuOpen}>
          <BurgerMenuIcon />
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="nav-bar__menu-button">
        <button onClick={onMenuOpen}>
          Menu
          <BurgerMenuIcon />
        </button>
      </div>
      <div className="nav-bar__search">
        <SearchIcon />
      </div>
    </>
  );
};

export const NavBar = ({ profile, contextLabel, onContextClick, onMenuOpen }) => {
  const { mobile } = useDevice();
  const navigate = useNavigate();
  const { onLogout } = useAuth();

  const menuItems = [
    {
      text: 'My Profile',
      onClick: () => navigate(`/users/${profile?.id}/profile`)
    },
    {
      text: 'Logout',
      onClick: onLogout
    }
  ];

  return (
    <header className="nav-bar">
      <section className="nav-bar__section">
        <section className="nav-bar__actions">
          <MenuButton onMenuOpen={onMenuOpen} />
        </section>
        <div className="nav-bar__logo">
          <Link to="/dashboard">{mobile ? <NesloIcon /> : <Logo />}</Link>
        </div>
      </section>
      <section className="nav-bar__section">
        <NotificationsProvider>
          <NotificationDropdown profile={profile} menuItems={menuItems} />
        </NotificationsProvider>
        <Dropdown menuItems={menuItems} placement="bottom-end" offset={mobile ? [0, 5] : [0, 0]}>
          <div className="nav-bar__profile">
            {!mobile && <small>{profile?.fullName}</small>}
            <img
              src={profile?.image ?? profile?.defaultImage}
              referrerPolicy="no-referrer"
              alt={USER_AVATAR_ALT}
            />
          </div>
        </Dropdown>
        {!mobile && (
          <div className="nav-bar__context">
            <Button label={contextLabel} onClick={onContextClick} />
          </div>
        )}
      </section>
    </header>
  );
};

NavBar.defaultProps = {
  contextLabel: 'Track your time',
  onContextClick: noOp
};

NavBar.propTypes = {
  profile: PROFILE_TYPE,
  contextLabel: string,
  onContextClick: func,
  onMenuOpen: func
};
