import { arrayOf } from 'prop-types';
import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SNACK_SUCCESS, isEmpty } from '@neslotech/utils';

import { mapNominationComments } from '../../../tool/kudos.util';
import { NOMINATION_TYPE } from '../../../tool/prop-types';

import {
  createKudosCommentReply,
  createKudosNominationComment,
  loadNominationCommentReplies,
  loadNominationComments,
  removeNominationComment,
  updateNominationComment
} from '../../../state/action/kudos/comment.actions';
import { toggleLike } from '../../../state/action/kudos/kudos.actions';
import { addSystemNotice } from '../../../state/action/system.actions';

import { useAuth } from '../../../hook/useAuth';
import { useProgressLoader } from '../../../hook/useProgressLoader';

import KudosNominationCard from '../../../component/kudos/timeline/KudosNominationCard';

const KudosNominationCardsContainer = ({ nominations }) => {
  const { id } = useAuth();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();
  const dispatch = useDispatch();

  const { directoryUsers, nominationComments, commentReplies } = useSelector(
    ({ kudos_store, directory_store }) => ({
      directoryUsers: directory_store.directoryUsers,
      nominationComments: kudos_store.nominationComments,
      commentReplies: kudos_store.commentReplies
    }),
    //Prevent re-render on each useSelector call
    shallowEqual
  );

  const onCommentSubmit = (nominationId, data, setComment) => {
    setBusyState(true);
    dispatch(
      createKudosNominationComment(
        nominationId,
        data,
        navigate,
        () => {
          setBusyState(false);
          setComment('');
        },
        () => setBusyState(false)
      )
    );
  };

  const onReplySubmit = (nominationId, commentId, data, setReply) => {
    setBusyState(true);
    dispatch(
      createKudosCommentReply(
        nominationId,
        commentId,
        data,
        navigate,
        () => {
          setBusyState(false);
          setReply('');
        },
        () => setBusyState(false)
      )
    );
  };

  const onNominationLikeToggle = (nominationId, onFail) => {
    setBusyState(true);
    dispatch(toggleLike(nominationId, id, navigate, () => setBusyState(false), onFail));
  };

  const handleLoadComments = (nominationId) => {
    if (!!nominationId) {
      dispatch(loadNominationComments(nominationId, navigate));
    }
  };

  const handleLoadReplies = (nominationId, commentId) => {
    if (!!nominationId && !!commentId) {
      dispatch(loadNominationCommentReplies(nominationId, commentId, navigate));
    }
  };

  const mappedNominationComments = useMemo(() => {
    if (isEmpty(directoryUsers)) {
      return [];
    }

    return mapNominationComments(nominationComments, directoryUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryUsers, nominationComments]);

  const onRemoveComment = (nominationId, commentId, user) => {
    const removeSuccessNotice = () => {
      if (user) {
        dispatch(
          addSystemNotice(`${user}'s comment has been removed successfully.`, SNACK_SUCCESS)
        );
      }
    };

    dispatch(removeNominationComment(nominationId, commentId, navigate, removeSuccessNotice));
  };

  const onUpdateNominationComment = (nominationId, commentId, data, setEditing, type) => {
    setBusyState(true);
    dispatch(
      updateNominationComment(
        nominationId,
        commentId,
        data,
        navigate,
        () => {
          dispatch(
            addSystemNotice(
              `Kudos nomination ${type} has been updated successfully.`,
              SNACK_SUCCESS
            )
          );
        },
        () => {
          setBusyState(false);
          setEditing(false);
        }
      )
    );
  };

  const mappedCommentReplies = useMemo(() => {
    if (isEmpty(directoryUsers)) {
      return [];
    }

    return mapNominationComments(commentReplies, directoryUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryUsers, commentReplies]);

  return nominations.map((nomination) => (
    <KudosNominationCard
      nomination={nomination}
      key={nomination.id}
      onNominationLikeToggle={onNominationLikeToggle}
      onLoadComments={handleLoadComments}
      onLoadReplies={handleLoadReplies}
      nominationComments={mappedNominationComments}
      commentReplies={mappedCommentReplies}
      onReplySubmit={onReplySubmit}
      onCommentSubmit={onCommentSubmit}
      onRemoveComment={onRemoveComment}
      onUpdateNominationComment={onUpdateNominationComment}
    />
  ));
};

KudosNominationCardsContainer.defaultProps = {
  nominations: []
};

KudosNominationCardsContainer.propTypes = {
  nominations: arrayOf(NOMINATION_TYPE)
};

export default KudosNominationCardsContainer;
