import { arrayOf } from 'prop-types';
import React, { useState } from 'react';

import { VIEW_OPTIONS } from '../../../tool/constant';
import { MOCK_JIRA_RESPONSE } from '../../../tool/mocks';
import { USER_TYPE } from '../../../tool/prop-types';

import { useAuth } from '../../../hook/useAuth';

import { Dropdown } from '../../../common/component/dropdown/Dropdown';
import { SearchOptions } from '../../../common/component/input/search/options/SearchOptions';

import { ReactComponent as ChevronLeftIcon } from '../../../icon/chev-left-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../../../icon/chev-right-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';
import { ReactComponent as SidebarIcon } from '../../../icon/sidebar-icon.svg';

import { SchedulingCalendar } from './calendar/SchedulingCalendar';
import { JiraSidebar } from './calendar/jira/JiraSidebar';

import './scheduling-dashboard.scss';

export const SchedulingDashboard = ({ users }) => {
  const { id } = useAuth();

  const TEAM_VIEW = 'team_view';

  const [selectedOption, setSelectedOption] = useState(TEAM_VIEW);

  const onChange = ({ option }) => {
    setSelectedOption(option);
  };

  const filteredUsers =
    selectedOption !== TEAM_VIEW ? users.filter((user) => user.id === id) : users;

  return (
    <>
      <section className="scheduling-dashboard">
        <header>
          <SearchOptions
            name="option"
            label={VIEW_OPTIONS.find((option) => option.value === selectedOption).label}
            options={VIEW_OPTIONS}
            value={selectedOption}
            onChange={onChange}
            secondaryLabel=""
            focused
          />
          <div className="scheduling-dashboard__controls">
            <div>
              <span className="scheduling-dashboard__navigation-left">
                <ChevronLeftIcon />
              </span>
              <span className="scheduling-dashboard__navigation-date">Today</span>
              <span className="scheduling-dashboard__navigation-right">
                <ChevronRightIcon />
              </span>
            </div>
            <Dropdown
              dropdownChildren={<JiraSidebar projects={MOCK_JIRA_RESPONSE} />}
              offset={[75, 0]}
              placement="bottom-end"
            >
              <div className="scheduling-dashboard__sidebar-icon">
                <SidebarIcon />
              </div>
            </Dropdown>
            <div>
              <PlusIcon />
            </div>
          </div>
        </header>
      </section>
      <SchedulingCalendar users={filteredUsers} personal={selectedOption !== TEAM_VIEW} />
    </>
  );
};

SchedulingDashboard.propTypes = {
  users: arrayOf(USER_TYPE)
};
