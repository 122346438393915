import { arrayOf, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import { getClassNames, noOp } from '@neslotech/utils';

import { DropdownMenu } from './DropdownMenu';

import './dropdown.scss';

/**
 * Dropdown options setup with placement and offset (menu distance from trigger).
 *
 * @param {String} placement - where to place the dropdown, relative to trigger
 *  ('top', 'top-start', 'bottom-end', 'left', 'right', etc.)
 * @param modifiers popper modifiers array.
 */
const getPopperOptions = (placement, modifiers) => ({
  placement,
  modifiers
});

/**
 * Decide whether to display the overlay, based on `popperOpen`. Will
 *  hide the dropdown menu if clicked.
 *
 * @param {Boolean} popperOpen - state of whether the dropdown menu is visible
 * @param {Function} setPopperOpen - function to set value of popperOpen
 * @param expandable
 */
const renderOverlay = (popperOpen, setPopperOpen, expandable) =>
  popperOpen && (
    <div
      className="dropdown__overlay"
      onKeyDown={noOp}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const target = e.target;
        if (expandable && target.localName === 'button') {
          return;
        }

        setPopperOpen(false);
      }}
    />
  );

export const Dropdown = ({
  children,
  dropdownChildren,
  menuItems,
  placement,
  expandable,
  multi,
  sameWidth,
  offset,
  closed
}) => {
  const modifiers = useMemo(() => {
    const offSetModifier = {
      name: 'offset',
      options: {
        offset
      }
    };

    if (sameWidth) {
      return [
        offSetModifier,
        {
          name: 'sameWidth',
          enabled: true,
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          phase: 'beforeWrite',
          requires: ['computeStyles']
        }
      ];
    }

    return offSetModifier;
  }, [offset, sameWidth]);

  // reference and setter to the dropdown trigger element:
  const [referenceElement, setReferenceElement] = useState(null);

  // reference and setter to the popup/dropdown element:
  const [popperElement, setPopperElement] = useState(null);

  // dropdown visibility control:
  const [popperOpen, setPopperOpen] = useState(!closed);

  useEffect(() => {
    setPopperOpen(!closed);
  }, [closed]);

  // initialise dropdown, with custom options
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    getPopperOptions(placement, modifiers)
  );

  return (
    <>
      <div
        className="dropdown__trigger"
        ref={setReferenceElement}
        onKeyDown={noOp}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (expandable) {
            return;
          }

          setPopperOpen(!popperOpen);
        }}
      >
        {children}
      </div>

      {renderOverlay(popperOpen, setPopperOpen)}

      <div
        className={getClassNames('dropdown__popper', { open: popperOpen })}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {!menuItems && <>{dropdownChildren}</>}
        {menuItems && (
          <DropdownMenu
            // justified={justified}
            menuItems={menuItems}
            selectItem={() => (expandable || multi ? noOp() : setPopperOpen(false))}
          />
        )}
      </div>
    </>
  );
};

Dropdown.defaultProps = {
  placement: 'bottom',
  expandable: false,
  justified: false,
  sameWidth: false,
  closed: true,
  offset: [0, 4],
  children: [],
  dropdownChildren: []
};

Dropdown.propTypes = {
  menuItems: arrayOf(
    shape({
      text: oneOfType([string, object]),
      onClick: func
    })
  ),
  placement: string,
  expandable: bool,
  justified: bool,
  closed: bool,
  offset: arrayOf(number),
  sameWidth: bool,
  children: oneOfType([arrayOf(node), node]),
  dropdownChildren: oneOfType([arrayOf(node), node])
};
