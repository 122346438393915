export const CREATE_REPORT = '[KUDOS_STORE] Create report';

export const createReport = (nominationId, payload, navigate, onSuccess, onComplete) => ({
  type: CREATE_REPORT,
  nominationId,
  payload,
  navigate,
  onSuccess,
  onComplete
});
