import { noOp } from '@neslotech/utils';

const STORE_NAME = '[PROJECT_STORE]';

export const SET_PROJECTS = `${STORE_NAME} Set projects`;

export const LOAD_ALL_PROJECTS = `${STORE_NAME} Load all projects`;
export const loadAllProjects = (navigate, onComplete = noOp) => ({
  type: LOAD_ALL_PROJECTS,
  navigate,
  onComplete
});

export const LOAD_PROJECT = `${STORE_NAME} Load project`;
export const loadProject = (id, navigate, onComplete) => ({
  type: LOAD_PROJECT,
  id,
  navigate,
  onComplete
});

export const CREATE_PROJECT = `${STORE_NAME} Create project`;
export const SET_PROJECT = `${STORE_NAME} Set project`;
export const createProject = (payload, navigate, onComplete) => ({
  type: CREATE_PROJECT,
  payload,
  navigate,
  onComplete
});

export const UPDATE_PROJECT = `${STORE_NAME} Update project`;
export const updateProject = (id, payload, navigate, onSuccess, onComplete) => ({
  type: UPDATE_PROJECT,
  id,
  payload,
  navigate,
  onSuccess,
  onComplete
});

export const SAVE_TEAM_MEMBERS = `${STORE_NAME} Save team members`;
export const saveTeamMembers = (projectId, payload, navigate, onSuccess, onComplete) => ({
  type: SAVE_TEAM_MEMBERS,
  projectId,
  payload,
  navigate,
  onSuccess,
  onComplete
});

export const ARCHIVE_PROJECT = `${STORE_NAME} Archive project`;
export const archiveProject = (projectId, navigate, onComplete) => ({
  type: ARCHIVE_PROJECT,
  projectId,
  navigate,
  onComplete
});

export const REINSTATE_PROJECT = `${STORE_NAME} Reinstate project`;
export const reinstateProject = (projectId, navigate, onComplete) => ({
  type: REINSTATE_PROJECT,
  projectId,
  navigate,
  onComplete
});

export const CLEAR_PROJECT = `${STORE_NAME} Clear project`;
export const clearProject = () => ({ type: CLEAR_PROJECT });
