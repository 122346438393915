import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { getAuthHeaders } from '../../../../tool/auth.util';
import { RESPONSE_STATUS } from '../../../../tool/constant';
import { API_HOST } from '../../../tool/api';

import { logout } from '../../../action/auth.actions';
import {
  ARCHIVE_LEAVE_CATEGORY,
  CREATE_LEAVE_CATEGORY,
  LOAD_ALL_LEAVE_CATEGORIES,
  REINSTATE_LEAVE_CATEGORY,
  SET_LEAVE_CATEGORIES,
  UPDATE_LEAVE_CATEGORY,
  loadAllLeaveCategories
} from '../../../action/scheduling/settings/leave-category.actions';
import { addSystemNotice } from '../../../action/system.actions';

export const LEAVE_CATEGORIES_ENDPOINT = `${API_HOST}/v1/scheduling/leave-categories`;

export function* performLoadAllLeaveCategories({ navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      LEAVE_CATEGORIES_ENDPOINT,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_LEAVE_CATEGORIES, categories: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('Failed to load leave categories', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadAllLeaveCategories() {
  yield takeLatest(LOAD_ALL_LEAVE_CATEGORIES, performLoadAllLeaveCategories);
}

export function* performCreateLeaveCategory({ leaveCategory, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      LEAVE_CATEGORIES_ENDPOINT,
      HttpVerb.POST,
      getAuthHeaders(),
      leaveCategory
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Time off category successfully created', SNACK_SUCCESS));
    yield put(loadAllLeaveCategories(navigate));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate, onComplete));
    }

    yield put(addSystemNotice('A system error occurred. Please try again later', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateLeaveCategory() {
  yield takeLatest(CREATE_LEAVE_CATEGORY, performCreateLeaveCategory);
}

export function* performArchiveLeaveCategory({ leaveCategoryId, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${LEAVE_CATEGORIES_ENDPOINT}/${leaveCategoryId}`,
      HttpVerb.DELETE,
      getAuthHeaders()
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Time off category archived successfully', SNACK_SUCCESS));
    yield put(loadAllLeaveCategories(navigate));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('A system error occurred. Please try again later', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForArchiveLeaveCategory() {
  yield takeLatest(ARCHIVE_LEAVE_CATEGORY, performArchiveLeaveCategory);
}

export function* performReinstateLeaveCategory({ leaveCategoryId, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${LEAVE_CATEGORIES_ENDPOINT}/${leaveCategoryId}/reinstate`,
      HttpVerb.PUT,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Time off category unarchived successfully', SNACK_SUCCESS));
    yield put(loadAllLeaveCategories(navigate));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('A system error occurred. Please try again later', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForReinstateLeaveCategory() {
  yield takeLatest(REINSTATE_LEAVE_CATEGORY, performReinstateLeaveCategory);
}

export function* performUpdateLeaveCategory({
  leaveCategoryId,
  leaveCategory,
  navigate,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${LEAVE_CATEGORIES_ENDPOINT}/${leaveCategoryId}`,
      HttpVerb.PUT,
      getAuthHeaders(),
      leaveCategory
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Time off category updated successfully', SNACK_SUCCESS));

    yield put(loadAllLeaveCategories(navigate));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    if (response?.status === RESPONSE_STATUS.CONFLICT) {
      yield put(
        addSystemNotice(
          'Category name already exists. Please choose a different name',
          SNACK_CRITICAL
        )
      );
    } else {
      yield put(addSystemNotice('A system error occurred. Please try again later', SNACK_CRITICAL));
    }
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateLeaveCategory() {
  yield takeLatest(UPDATE_LEAVE_CATEGORY, performUpdateLeaveCategory);
}

export default function* leaveCategorySaga() {
  yield all([
    watchForLoadAllLeaveCategories(),
    watchForCreateLeaveCategory(),
    watchForArchiveLeaveCategory(),
    watchForReinstateLeaveCategory(),
    watchForUpdateLeaveCategory()
  ]);
}
