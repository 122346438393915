import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { RESPONSE_STATUS } from '../../../tool/constant';
import { mapErrors } from '../../../tool/error.util';
import {
  getCreateKudosNominationRequest,
  getLoadCompanyNominationsRequest,
  getLoadLeadersRequest,
  getLoadMostFrequentCategoriesRequest,
  getLoadMostPopularCategoriesRequest,
  getLoadPersonalNominationsRequest,
  getLoadRedeemablePointsRequest,
  getLoadRemainingPointsRequest,
  getRemoveNominationMessageRequest,
  getToggleLike,
  loadActiveCategoriesRequest
} from '../../tool/api/kudos/kudos.endpoint';

import { logout } from '../../action/auth.actions';
import {
  ADD_REALTIME_PERSONAL_NOMINATION,
  CREATE_KUDOS_NOMINATION,
  LOAD_CATEGORIES,
  LOAD_COMPANY_NOMINATIONS,
  LOAD_LEADERS,
  LOAD_MOST_FREQUENT_CATEGORIES,
  LOAD_MOST_POPULAR_CATEGORIES,
  LOAD_PERSONAL_NOMINATIONS,
  LOAD_REDEEMABLE_POINTS,
  LOAD_REMAINING_POINTS,
  REMOVE_NOMINATION_MESSAGE,
  SET_ALL_TIME_POINTS,
  SET_CATEGORIES,
  SET_COMPANY_NOMINATIONS,
  SET_KUDOS_ERRORS,
  SET_LEADERS,
  SET_MOST_FREQUENT_CATEGORIES,
  SET_MOST_POPULAR_CATEGORIES,
  SET_PERSONAL_NOMINATIONS,
  SET_REDEEMABLE_POINTS,
  SET_REMAINING_POINTS,
  TOGGLE_LIKE
} from '../../action/kudos/kudos.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performLoadMostFrequentCategories({ userId, navigate }) {
  try {
    const [endpoint, requestOptions] = getLoadMostFrequentCategoriesRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MOST_FREQUENT_CATEGORIES, frequentCategories: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos points data could not be loaded!', SNACK_CRITICAL));
  }
}

export function* watchForLoadMostFrequentCategories() {
  yield takeLeading(LOAD_MOST_FREQUENT_CATEGORIES, performLoadMostFrequentCategories);
}

export function* performLoadRemainingPoints({ userId, navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadRemainingPointsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_REMAINING_POINTS, remainingPoints: data.remainingPoints });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos points data could not be loaded!', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadRemainingPoints() {
  yield takeLeading(LOAD_REMAINING_POINTS, performLoadRemainingPoints);
}

export function* performLoadRedeemablePoints({ userId, navigate }) {
  try {
    const [endpoint, requestOptions] = getLoadRedeemablePointsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_REDEEMABLE_POINTS, redeemablePoints: data.accumulatedPoints });
    // TODO Remove once the redeem points functionality is implemented.
    yield put({ type: SET_ALL_TIME_POINTS, allTimePoints: data.accumulatedPoints });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos points data could not be loaded!', SNACK_CRITICAL));
  }
}

export function* watchForLoadRedeemablePoints() {
  yield takeLeading(LOAD_REDEEMABLE_POINTS, performLoadRedeemablePoints);
}

export function* performLoadLeaders({ onComplete, navigate }) {
  try {
    const [endpoint, requestOptions] = getLoadLeadersRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_LEADERS, leaders: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }
    yield put(
      addSystemNotice('There was an error while retrieving the leaderboard.', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadLeaders() {
  yield takeLeading(LOAD_LEADERS, performLoadLeaders);
}

export function* performLoadMostPopularCategories({ navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadMostPopularCategoriesRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MOST_POPULAR_CATEGORIES, popularCategories: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(
      addSystemNotice('The kudos popular categories data could not be loaded!', SNACK_CRITICAL)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadMostPopularCategories() {
  yield takeLeading(LOAD_MOST_POPULAR_CATEGORIES, performLoadMostPopularCategories);
}

export function* performLoadPersonalNominations({ userId, onComplete, navigate }) {
  try {
    const [endpoint, requestOptions] = getLoadPersonalNominationsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PERSONAL_NOMINATIONS, personalNominations: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(
      addSystemNotice('The kudos personal nominations data could not be loaded!', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPersonalNominations() {
  yield takeLeading(LOAD_PERSONAL_NOMINATIONS, performLoadPersonalNominations);
}

export function* performLoadCompanyNominations({ onComplete, navigate }) {
  try {
    const [endpoint, requestOptions] = getLoadCompanyNominationsRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPANY_NOMINATIONS, companyNominations: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }
    yield put(
      addSystemNotice('There was an error while retrieving the Nominations.', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCompanyNominations() {
  yield takeLeading(LOAD_COMPANY_NOMINATIONS, performLoadCompanyNominations);
}

export function* performLoadCategories({ navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = loadActiveCategoriesRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CATEGORIES, categories: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos categories data could not be loaded!', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCategories() {
  yield takeLeading(LOAD_CATEGORIES, performLoadCategories);
}

export function* performCreateKudosNomination({ payload, navigate, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getCreateKudosNominationRequest(payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }

    yield put({ type: ADD_REALTIME_PERSONAL_NOMINATION, nomination: data });

    yield put(addSystemNotice('Your kudos nomination was created successfully.', SNACK_SUCCESS));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });
    yield put(addSystemNotice('Your kudos nomination could not be created.', SNACK_CRITICAL));
    if (onError) {
      yield call(onError);
    }
  }
}

export function* watchForCreateKudosNominationRequest() {
  yield takeLeading(CREATE_KUDOS_NOMINATION, performCreateKudosNomination);
}

export function* performRemoveNominationMessage({ nominationId, user, navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getRemoveNominationMessageRequest(nominationId);

    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName}'s message has been removed.`,
        SNACK_SUCCESS
      )
    );
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('System error, please try again.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForRemoveNominationMessage() {
  yield takeLeading(REMOVE_NOMINATION_MESSAGE, performRemoveNominationMessage);
}

export function* performToggleLike({ nominationId, likerId, navigate, onComplete, onFail }) {
  try {
    const [endpoint, requestOptions] = getToggleLike(nominationId, likerId);

    yield call(axios, endpoint, requestOptions);
  } catch ({ response }) {
    yield call(onFail);

    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }
  } finally {
    yield call(onComplete);
  }
}

export function* watchForToggleLike() {
  yield takeLeading(TOGGLE_LIKE, performToggleLike);
}

export default function* kudosSaga() {
  yield all([
    watchForLoadMostFrequentCategories(),
    watchForLoadRemainingPoints(),
    watchForLoadRedeemablePoints(),
    watchForLoadLeaders(),
    watchForLoadMostPopularCategories(),
    watchForLoadCompanyNominations(),
    watchForLoadPersonalNominations(),
    watchForLoadCategories(),
    watchForCreateKudosNominationRequest(),
    watchForRemoveNominationMessage(),
    watchForToggleLike()
  ]);
}
