export const SET_USERS = '[USER_STORE] Set Users';
export const SET_USER = '[USER_STORE] Set User';
export const SET_ERRORS = '[USER_STORE] Set Errors';

export const LOAD_USERS = '[USER_STORE] Load Users';
export const loadUsers = (navigate, onComplete) => ({
  type: LOAD_USERS,
  navigate,
  onComplete
});

export const ADD_USER = '[USER_STORE] Add User';
export const addUser = (payload, navigate, onSuccess, onComplete) => ({
  type: ADD_USER,
  payload,
  navigate,
  onSuccess,
  onComplete
});

export const REMOVE_USER = '[USER_STORE] Remove User';
export const removeUser = (user, navigate, onComplete) => ({
  type: REMOVE_USER,
  user,
  navigate,
  onComplete
});

export const CHANGE_USER_ROLE = '[CHANGE_ROLE] Change User role';
export const changeUserRole = (user, navigate, onComplete) => ({
  type: CHANGE_USER_ROLE,
  user,
  navigate,
  onComplete
});

export const CLEAR_ERRORS = '[USER_STORE] Clear Errors';
export const clearErrors = () => ({
  type: CLEAR_ERRORS
});
