import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { removeUser } from '../../../../state/action/user.action';

import { useProgressLoader } from '../../../../hook/useProgressLoader';

import RemoveUser from '../../../../component/user/management/remove/RemoveUser';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';

const RemoveUserContainer = ({ onClose, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setBusyState, setFullScreen } = useProgressLoader();

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  const onComplete = () => {
    onClose();
    setBusyState(false);
  };

  const handleConfirmClick = () => {
    setBusyState(true);
    dispatch(removeUser(user, navigate, onComplete));
  };

  return (
    <Modal>
      <ModalLayout onClose={onClose} title="Are You Sure?">
        <RemoveUser user={user} onConfirmClick={handleConfirmClick} onCancelClick={onClose} />
      </ModalLayout>
    </Modal>
  );
};

export default RemoveUserContainer;
