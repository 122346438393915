import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { RESPONSE_STATUS } from '../../tool/constant';
import { getLoadDirectoryUsersRequest } from '../tool/api/directory.endpoint';

import { logout } from '../action/auth.actions';
import { LOAD_DIRECTORY_USERS, SET_DIRECTORY_USERS } from '../action/directory.actions';
import { addSystemNotice } from '../action/system.actions';

export function* performLoadDirectoryUsers({ onComplete, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadDirectoryUsersRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_DIRECTORY_USERS, directoryUsers: data });

    yield call(onComplete);
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The directory users could not be found.', SNACK_CRITICAL));

    yield call(onComplete);
  }
}

export function* watchForLoadDirectoryUsers() {
  yield takeLatest(LOAD_DIRECTORY_USERS, performLoadDirectoryUsers);
}

export default function* directorySaga() {
  yield all([watchForLoadDirectoryUsers()]);
}
