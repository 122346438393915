import React from 'react';
import { Link, Navigate, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';
import SchedulingRoute from '../provider/SchedulingRoute.provider';
import SchedulingSettingsRoute from '../provider/SchedulingSettingsRoute.provider';

import SchedulingUserAccessContainer from '../container/scheduling/access/SchedulingUserAccessContainer';
import ClientListContainer from '../container/scheduling/client/ClientList.container';
import { ClientAddContainer } from '../container/scheduling/client/detail/create/ClientAdd.container';
import { ClientEditContainer } from '../container/scheduling/client/detail/edit/ClientEdit.container';
import { SchedulingDashboardContainer } from '../container/scheduling/dashboard/SchedulingDashboard.container';
import { LeaveCategoryListContainer } from '../container/scheduling/leave-category/LeaveCategoryList.container';
import ProjectListContainer from '../container/scheduling/project/ProjectList.container';
import AddProjectContainer from '../container/scheduling/project/add/AddProject.container';
import EditProjectContainer from '../container/scheduling/project/edit/EditProject.container';

import AppLayout from '../component/layout/AppLayout';

export const schedulingRoutes = () => (
  <Route path="scheduling">
    {/* ADMIN FIRST TIME ACCESS */}
    <Route
      path="setup/access"
      handle={{
        crumb: () => <Link to="/scheduling/setup/access">Select Team Member Access</Link>,
        pageTitle: 'User Access | Scheduling'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <AppLayout fullWidthBreadcrumb>
            <SchedulingUserAccessContainer />
          </AppLayout>
        </ProtectedRoute>
      }
    />

    {/* ADMIN & EMPLOYEE NON FIRST TIME ACCESS (aka dashboard) */}
    <Route
      path="dashboard"
      handle={{ pageTitle: 'Dashboard | Scheduling' }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <SchedulingRoute />
        </ProtectedRoute>
      }
    >
      <Route index element={<SchedulingDashboardContainer />} />
    </Route>

    {/* ADMIN PROJECT SETTINGS */}
    <Route
      path="projects"
      handle={{
        crumb: () => [
          <Link to="/scheduling/dashboard">Dashboard</Link>,
          <Link to="/scheduling/projects">Project Management</Link>
        ],
        pageTitle: 'Projects | Scheduling'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <SchedulingRoute allowedRoles={[ROLES.ADMIN]} />
        </ProtectedRoute>
      }
    >
      <Route index element={<ProjectListContainer />} />
      <Route
        path="add"
        handle={{
          crumb: () => <Link to="/scheduling/projects/add">Project Details</Link>,
          pageTitle: 'Project Details | Scheduling'
        }}
        element={<AddProjectContainer />}
      />
      <Route
        path=":id"
        handle={{
          crumb: () => <Link to="/scheduling/projects/add">Project Details</Link>,
          pageTitle: 'Project Details | Scheduling'
        }}
        element={<AddProjectContainer />}
      />
      <Route
        path=":id/edit"
        handle={{
          crumb: ({ params: { id } }) => (
            <Link to={`/scheduling/projects/${id}/edit`}>Project Details</Link>
          ),
          pageTitle: 'Edit Project Details | Scheduling'
        }}
        element={<EditProjectContainer />}
      />
    </Route>

    {/* ADMIN CLIENT SETTINGS */}
    <Route
      path="clients"
      handle={{
        crumb: () => [
          <Link to="/scheduling/dashboard">Dashboard</Link>,
          <Link to="/scheduling/clients">Client Management</Link>
        ],
        pageTitle: 'Clients | Scheduling'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <SchedulingRoute allowedRoles={[ROLES.ADMIN]} />
        </ProtectedRoute>
      }
    >
      <Route index element={<ClientListContainer />} />
      <Route
        path=":id/edit"
        handle={{
          crumb: ({ params: { id } }) => (
            <Link to={`/scheduling/clients/${id}/edit`}>Client Details</Link>
          ),
          pageTitle: 'Client Edit | Scheduling'
        }}
        element={<ClientEditContainer />}
      />
      <Route
        path="add"
        handle={{
          crumb: () => <Link to="/scheduling/clients/add">Client Details</Link>,
          pageTitle: 'Client Add | Scheduling'
        }}
        element={<ClientAddContainer />}
      />
    </Route>

    <Route
      path="settings"
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <SchedulingSettingsRoute allowedRoles={[ROLES.ADMIN]} />
        </ProtectedRoute>
      }
    >
      <Route index element={<Navigate to="/scheduling/settings/access" replace />} />
      <Route
        path="access"
        handle={{
          crumb: () => [
            <Link to="/scheduling/dashboard">Dashboard</Link>,
            <Link to="/scheduling/settings/access">Select Team Member Access</Link>
          ],
          pageTitle: 'User Access | Scheduling'
        }}
        element={<SchedulingUserAccessContainer />}
      />
      <Route
        path="leave-categories"
        handle={{
          crumb: () => [
            <Link to="/scheduling/dashboard">Dashboard</Link>,
            <Link to="/scheduling/settings/leave-categories">Category Management</Link>
          ],
          pageTitle: 'Category Management | Scheduling'
        }}
        element={<LeaveCategoryListContainer />}
      />
    </Route>

    <Route path="*" element={<Navigate to="/scheduling/dashboard" replace />} />
  </Route>
);
