import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { howLongAgo, isEmpty } from '@neslotech/utils';

import { NOTIFICATION_TYPE } from '../component/notification/notifications.helper';

import { loadDirectoryUsers } from '../state/action/directory.actions';
import {
  invertReadFlag,
  loadAllNotifications,
  markAllNotificationsAsRead,
  setAllNotifications
} from '../state/action/notification.actions';

import { NotificationsContext } from '../context/Notifications.context';

import { useAuth } from '../hook/useAuth';
import { useProgressLoader } from '../hook/useProgressLoader';

import profileIcon from '../icon/profile-icon.svg';

const NotificationsProvider = ({ children }) => {
  const { id } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();

  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);

  const allNotifications = useSelector(
    ({ notifications_store }) => notifications_store.allNotifications
  );
  const notifsLoadingFromServer = useSelector(
    ({ notifications_store }) => notifications_store.loading
  );

  const memoizedHasUnreadNotifications = useMemo(
    () => (allNotifications ?? []).some((item) => !item.read),
    [allNotifications]
  );

  const [loading, setLoading] = useState(true);

  const afterUpdateOfNotification = () => {
    dispatch(
      setAllNotifications(allNotifications.map((notification) => ({ ...notification, read: true })))
    );
    setLoading(true);
    dispatch(loadAllNotifications(id, navigate));
  };

  const dontDisplaySenderTypes = [
    NOTIFICATION_TYPE.REMOVE_NOMINATION_MESSAGE,
    NOTIFICATION_TYPE.REMOVE_NOMINATION_COMMENT,
    NOTIFICATION_TYPE.REMOVE_NOMINATION
  ];

  const markNotificationsAsRead = () => {
    setBusyState(true);
    dispatch(
      markAllNotificationsAsRead(id, navigate, afterUpdateOfNotification, () => setBusyState(false))
    );
  };

  useEffect(() => {
    if (!!id && isEmpty(allNotifications)) {
      setLoading(true);
      dispatch(loadAllNotifications(id, navigate));
      dispatch(loadDirectoryUsers(navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!isEmpty(allNotifications) && !isEmpty(directoryUsers) && !notifsLoadingFromServer) {
      setLoading(false);
    } else if (isEmpty(allNotifications) && !notifsLoadingFromServer) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryUsers, allNotifications, notifsLoadingFromServer]);

  const mapNotifications = (allNotifications) => {
    return (allNotifications ?? []).map((notification) => {
      let sender = directoryUsers.find((user) => user.id === notification.createdBy);
      const displaySender = dontDisplaySenderTypes.every(
        (notificationType) => notificationType !== notification.type
      );

      if (!sender) {
        console.error('The sender could not be found: ', notification.createdBy);
        sender = {
          id: notification.createdBy,
          firstName: 'Unknown',
          lastName: 'User'
        };
      }

      if (
        notification.type === NOTIFICATION_TYPE.REMOVE_NOMINATION_COMMENT ||
        notification.type === NOTIFICATION_TYPE.REMOVE_NOMINATION_MESSAGE
      ) {
        let recipient = directoryUsers.find(
          (user) => user.id === notification.metadata.recipientId
        );

        if (!recipient) {
          console.error('The recipient could not be found: ', notification.metadata.recipientId);
          recipient = {
            id: notification.createdBy,
            firstName: 'Unknown',
            lastName: 'User'
          };
        }

        const message = notification.message.replace(
          '<RECIPIENT>',
          `${recipient.firstName} ${recipient.lastName}`
        );

        const sender = displaySender && (
          <span>
            {recipient.firstName} {recipient.lastName}
          </span>
        );

        return {
          ...notification,
          profileImageSrc: recipient.image ?? recipient.defaultImage,
          timeSent: howLongAgo(notification.createdDate, true).toLowerCase(),
          message: (
            <p>
              {sender}
              {message}
            </p>
          )
        };
      }

      const senderInfo = displaySender && (
        <span>
          {sender.firstName} {sender.lastName}
        </span>
      );

      return {
        ...notification,
        profileImageSrc: sender.image ?? sender.defaultImage ?? profileIcon,
        timeSent: howLongAgo(notification.createdDate, true).toLowerCase(),
        message: (
          <p>
            {senderInfo} {notification.message}
          </p>
        )
      };
    });
  };

  const mappedNotifications = useMemo(() => {
    if (isEmpty(directoryUsers)) {
      return [];
    }

    return mapNotifications(allNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications, directoryUsers]);

  const mappedUnreadNotifications = useMemo(() => {
    return (mappedNotifications ?? []).filter((notification) => !notification.read);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedNotifications]);

  const onNotificationReadToggle = (notificationId, read) => {
    setBusyState(true);
    dispatch(invertReadFlag(id, notificationId, navigate, () => setBusyState(false)));
    dispatch(
      setAllNotifications(
        allNotifications.map((notification) => ({
          ...notification,
          read: notification.id === notificationId ? read : notification.read
        }))
      )
    );
  };

  const handleClick = (setClosed, notification) => {
    if (notification.metadata && notification.metadata.nominationId) {
      let path = `/kudos?nominationId=${notification.metadata.nominationId}`;
      if (notification.metadata.commentId) {
        path += `&commentId=${notification.metadata.commentId}`;
      }

      if (notification.metadata.replyId) {
        path += `&replyId=${notification.metadata.replyId}`;
      }

      navigate(path);
      setClosed(true);
    }
  };

  const value = {
    loading,
    notifications: mappedNotifications,
    unReadNotifications: mappedUnreadNotifications,
    markNotificationsAsRead,
    hasUnreadNotifications: memoizedHasUnreadNotifications,
    handleClick,
    onNotificationReadToggle
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsProvider;
