import { all, call, put, takeLatest, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, isEmpty } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import { RESPONSE_STATUS } from '../../../tool/constant';
import {
  SCHEDULING_ENDPOINT,
  getLoadAllSchedulingPermissionsRequest,
  getSaveSchedulingPermissionsRequest
} from '../../tool/api/scheduling/scheduling-permissions.endpoint';

import { logout } from '../../action/auth.actions';
import {
  LOAD_ALL_SCHEDULING_PERMISSIONS,
  LOAD_USER_SCHEDULING_PERMISSION,
  SAVE_SCHEDULING_PERMISSIONS,
  SET_ALL_SCHEDULING_PERMISSIONS,
  SET_USER_SCHEDULING_PERMISSION
} from '../../action/scheduling/scheduling-permissions.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performSaveSchedulingPermissions({ payload, navigate, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getSaveSchedulingPermissionsRequest(payload);

    yield call(axios, endpoint, requestOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(
      addSystemNotice(
        'There was an error while selecting user access for scheduling',
        SNACK_CRITICAL
      )
    );
    yield call(onError);
  }
}

export function* watchForSaveSchedulingPermissionsRequest() {
  yield takeLeading(SAVE_SCHEDULING_PERMISSIONS, performSaveSchedulingPermissions);
}

export function* performLoadAllSchedulingPermissions({ navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadAllSchedulingPermissionsRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ALL_SCHEDULING_PERMISSIONS, permissions: data });

    if (isEmpty(data)) {
      yield put(
        addSystemNotice('Permissions for scheduling have not been set up yet', SNACK_CRITICAL)
      );
      yield call(navigate, '/scheduling/setup/access');
      return;
    }
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
      return;
    }

    if (response?.status === RESPONSE_STATUS.NOT_FOUND) {
      yield put(
        addSystemNotice('Permissions for scheduling have not been set up yet', SNACK_CRITICAL)
      );
      yield call(navigate, '/scheduling/setup/access', { replace: true });
      return;
    }

    yield put(
      addSystemNotice('There was an error while retrieving scheduling permissions', SNACK_CRITICAL)
    );

    yield call(navigate, '/dashboard');
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadSchedulingPermissionsRequest() {
  yield takeLeading(LOAD_ALL_SCHEDULING_PERMISSIONS, performLoadAllSchedulingPermissions);
}

export function* performLoadUserSchedulingPermission({ userId, navigate }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/permissions`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_USER_SCHEDULING_PERMISSION, userPermission: data });
  } catch ({ response }) {
    if (response?.status === RESPONSE_STATUS.UNAUTHORIZED) {
      yield put(logout(navigate));
      return;
    }

    if (response?.status === RESPONSE_STATUS.FORBIDDEN) {
      yield put(addSystemNotice('You do not have access to scheduling', SNACK_CRITICAL));
    } else {
      yield put(
        addSystemNotice(
          'Error while loading your scheduling permission, please contact an admin',
          SNACK_CRITICAL
        )
      );
    }

    yield call(navigate, '/dashboard');
  }
}

export function* watchForLoadSchedulingUserPermissionRequest() {
  yield takeLatest(LOAD_USER_SCHEDULING_PERMISSION, performLoadUserSchedulingPermission);
}

export default function* schedulingPermissionsSaga() {
  yield all([
    watchForSaveSchedulingPermissionsRequest(),
    watchForLoadSchedulingPermissionsRequest(),
    watchForLoadSchedulingUserPermissionRequest()
  ]);
}
