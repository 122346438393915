import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { removeSystemNotice } from './state/action/system.actions';

import DeviceProvider from './provider/Device.provider';
import ProgressLoaderProvider from './provider/ProgressLoader.provider';
import { PusherProvider } from './provider/Pusher.provider';

import { SnackBar } from './common/component/snack-bar/SnackBar';

import { appRoutes } from './route/app.route';

import './stylesheet/app.scss';

const App = () => {
  const dispatch = useDispatch();
  const notices = useSelector(({ system_store }) => system_store.notices);

  const handleCloseNotice = (noticeId) => {
    dispatch(removeSystemNotice(noticeId));
  };

  const router = createBrowserRouter(createRoutesFromElements(appRoutes()), {
    basename: process.env.PUBLIC_URL
  });

  return (
    <>
      <DeviceProvider>
        <PusherProvider>
          <ProgressLoaderProvider>
            <RouterProvider router={router} />
          </ProgressLoaderProvider>
        </PusherProvider>
      </DeviceProvider>
      <SnackBar notices={notices} onCloseNotice={handleCloseNotice} />
    </>
  );
};

export default App;
