import { noOp } from '@neslotech/utils';

export const SET_DIRECTORY_USERS = '[DIRECTORY_STORE] Set Directory Users';
export const LOAD_DIRECTORY_USERS = '[DIRECTORY_STORE] Load Directory Users';

export const loadDirectoryUsers = (navigate, onComplete = noOp) => ({
  type: LOAD_DIRECTORY_USERS,
  navigate,
  onComplete
});
