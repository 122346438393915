import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { saveTeamMembers } from '../../../../state/action/scheduling/project.actions';
import { loadUsers } from '../../../../state/action/user.action';

import ProjectMemberSelection from '../../../../component/scheduling/project/member/ProjectMemberSelection';

export const ProjectMembersContainer = ({ onNext, onBack }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stateLoading = useSelector(({ user_store }) => user_store.loading);
  const users = useSelector(({ user_store }) =>
    user_store.users?.filter((user) => user.active === true)
  );
  const { projectId, teamMembers } = useSelector(({ project_store: { project } }) => ({
    projectId: project?.id,
    teamMembers: project?.teamMembers?.map((userId) => ({ userId })) ?? []
  }));

  const [loading, setLoading] = useState(stateLoading ?? true);

  const handleNext = (selectedUsers, onComplete) => {
    dispatch(saveTeamMembers(projectId, { users: selectedUsers }, navigate, onNext, onComplete));
  };

  useEffect(() => {
    dispatch(loadUsers(navigate, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectMemberSelection
      existingMembers={teamMembers}
      users={users}
      loading={loading}
      onBack={onBack}
      onSubmit={handleNext}
    />
  );
};

ProjectMembersContainer.propTypes = {
  onBack: func.isRequired,
  onNext: func.isRequired
};
