import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { RESPONSE_STATUS } from '../../../tool/constant';
import { mapErrors } from '../../../tool/error.util';
import { getCreateReportRequest } from '../../tool/api/kudos/report.endpoint';

import { logout } from '../../action/auth.actions';
import { SET_KUDOS_ERRORS } from '../../action/kudos/kudos.actions';
import { CREATE_REPORT } from '../../action/kudos/report.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performCreateReport({ nominationId, payload, navigate, onSuccess, onComplete }) {
  try {
    const [endpoint, requestOptions] = getCreateReportRequest(nominationId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The nomination was removed successfully', SNACK_SUCCESS));
    yield call(onSuccess);
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });

    yield put(
      addSystemNotice('The nomination could not be removed. Please try again.', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateReportRequest() {
  yield takeLeading(CREATE_REPORT, performCreateReport);
}

export default function* kudosReportSaga() {
  yield all([watchForCreateReportRequest()]);
}
