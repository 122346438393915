import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { isEmpty, noOp } from '@neslotech/utils';

import {
  loadAllSchedulingPermissions,
  loadUserSchedulingPermission
} from '../state/action/scheduling/scheduling-permissions.actions';

import { SchedulingLayout } from '../component/layout/scheduling/SchedulingLayout';

const SchedulingRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(({ profile_store }) => profile_store.contextUser);
  const schedulingPermissionLoaded = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.userLoaded
  );
  const permissions = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.permissions
  );

  useEffect(() => {
    if (isEmpty(permissions)) {
      dispatch(loadAllSchedulingPermissions(navigate, noOp));
    } else if (!schedulingPermissionLoaded) {
      dispatch(loadUserSchedulingPermission(profile.id, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, permissions, schedulingPermissionLoaded]);

  return (
    <SchedulingLayout>
      <Outlet />
    </SchedulingLayout>
  );
};

export default SchedulingRoute;
