import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty, noOp } from '@neslotech/utils';

import { mapSchedulingUsers } from '../../../tool/scheduling.util';

import { loadAllSchedulingPermissions } from '../../../state/action/scheduling/scheduling-permissions.actions';
import { loadUsers } from '../../../state/action/user.action';

import FilterProvider from '../../../provider/Filter.provider';

import { SchedulingDashboard } from '../../../component/scheduling/dashboard/SchedulingDashboard';

export const SchedulingDashboardContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schedulingPermissions = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.permissions
  );
  const users = useSelector(({ user_store }) => user_store.users);

  useEffect(() => {
    if (isEmpty(users)) {
      dispatch(loadUsers(navigate, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (isEmpty(schedulingPermissions)) {
      dispatch(loadAllSchedulingPermissions(navigate, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulingPermissions]);

  const mappedSchedulingUsers = useMemo(
    () => mapSchedulingUsers(schedulingPermissions, users),
    [users, schedulingPermissions]
  );

  return (
    <FilterProvider>
      <SchedulingDashboard users={mappedSchedulingUsers} />
    </FilterProvider>
  );
};
