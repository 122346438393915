import {
  SET_ALL_SCHEDULING_PERMISSIONS,
  SET_USER_SCHEDULING_PERMISSION
} from '../../action/scheduling/scheduling-permissions.actions';

export const initialState = {
  permissions: [],
  allLoaded: false,
  userLoaded: false,
  userPermission: undefined
};

export function schedulingPermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_SCHEDULING_PERMISSIONS:
      const { permissions } = action;
      return {
        ...state,
        allLoaded: true,
        permissions
      };
    case SET_USER_SCHEDULING_PERMISSION:
      const { userPermission } = action;
      return {
        ...state,
        userLoaded: true,
        userPermission
      };
    default:
      return state;
  }
}
