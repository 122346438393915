export const SET_MOST_FREQUENT_CATEGORIES = '[KUDOS_STORE] Set Most Frequent Categories';
export const SET_REMAINING_POINTS = '[KUDOS_STORE] Set Remaining Points';
export const SET_REDEEMABLE_POINTS = '[KUDOS_STORE] Set Redeemable Points';
export const SET_ALL_TIME_POINTS = '[KUDOS_STORE] Set All Time Points';
export const SET_LEADERS = '[KUDOS_STORE] Set Leaders';
export const SET_COMPANY_NOMINATIONS = '[KUDOS_STORE] Set Company Nominations';
export const SET_MOST_POPULAR_CATEGORIES = '[KUDOS_STORE] Set Most Popular Categories';
export const SET_PERSONAL_NOMINATIONS = '[KUDOS_STORE] Set Personal Nomination';
export const SET_CATEGORIES = '[KUDOS_STORE] Set Categories';
export const SET_KUDOS_ERRORS = '[KUDOS_STORE] Set Kudos Errors';
export const CLEAR_KUDOS_ERRORS = '[KUDOS_STORE] Clear Kudos Errors';
export const REMOVE_NOMINATION_MESSAGE = '[KUDOS_STORE] Remove Nomination Message';

export const LOAD_MOST_FREQUENT_CATEGORIES = '[KUDOS_STORE] Load Most Frequent Categories';
export const LOAD_REMAINING_POINTS = '[KUDOS_STORE] Load Remaining Points';
export const LOAD_REDEEMABLE_POINTS = '[KUDOS_STORE] Load Redeemable Points';
export const LOAD_LEADERS = '[KUDOS_STORE] Load Leaders';
export const LOAD_PERSONAL_NOMINATIONS = '[KUDOS_STORE] Load Personal Nominations';
export const LOAD_COMPANY_NOMINATIONS = '[KUDOS_STORE] Load Company Nominations';
export const LOAD_MOST_POPULAR_CATEGORIES = '[KUDOS_STORE] Load Most Popular Categories';
export const LOAD_CATEGORIES = '[KUDOS_STORE] Load Categories';
export const CREATE_KUDOS_NOMINATION = '[KUDOS_STORE] Create Kudos Nomination';
export const ADD_REALTIME_POINTS = '[KUDOS_STORE] Add real time points';
export const ADD_REALTIME_PERSONAL_NOMINATION = '[KUDOS_STORE] Add real time personal nomination';
export const ADD_REALTIME_NOMINATION = '[KUDOS_STORE] Add real time nomination';
export const ADD_REALTIME_NOMINATION_MESSAGE_REMOVE =
  '[KUDOS_STORE] Add real time nomination message remove';
export const ADD_REALTIME_NOMINATION_DELETE = '[KUDOS_STORE] Add real time nomination delete';
export const SET_REALTIME_LEADERS = '[KUDOS_STORE] Set real time leaders';
export const SET_REALTIME_POPULAR_CATEGORIES = '[KUDOS_STORE] Set real time popular categories';
export const SET_REALTIME_NOMINATION_LIKE = '[KUDOS_STORE] Set real time nomination like';
export const SET_REALTIME_MOST_FREQUENT_CATEGORIES =
  '[KUDOS_STORE] Set real time most nominated categories';
export const SET_REALTIME_REMAINING_POINTS = '[KUDOS_STORE] Set real time remaining points';
export const TOGGLE_LIKE = '[KUDOS_STORE] toggle like';

export const loadMostFrequentCategories = (userId, navigate) => ({
  type: LOAD_MOST_FREQUENT_CATEGORIES,
  userId,
  navigate
});

export const loadRemainingPoints = (userId, navigate, onComplete) => ({
  type: LOAD_REMAINING_POINTS,
  userId,
  navigate,
  onComplete
});

export const loadRedeemablePoints = (userId, navigate) => ({
  type: LOAD_REDEEMABLE_POINTS,
  userId,
  navigate
});

export const addRealtimePoints = (pointsEvent) => ({
  type: ADD_REALTIME_POINTS,
  pointsEvent
});

export const loadLeaders = (navigate, onComplete) => ({
  type: LOAD_LEADERS,
  navigate,
  onComplete
});

export const setRealtimeLeaders = (leaders) => ({
  type: SET_REALTIME_LEADERS,
  leaders
});

export const setRealtimePopularCategories = (categories) => ({
  type: SET_REALTIME_POPULAR_CATEGORIES,
  categories
});

export const setRealtimeNominationLike = (profileId, likeEvent) => ({
  type: SET_REALTIME_NOMINATION_LIKE,
  profileId,
  likeEvent
});

export const setRealtimeMostFrequentCategories = (categories) => ({
  type: SET_REALTIME_MOST_FREQUENT_CATEGORIES,
  categories
});

export const loadCompanyNominations = (navigate, onComplete) => ({
  type: LOAD_COMPANY_NOMINATIONS,
  navigate,
  onComplete
});

export const loadMostPopularCategories = (navigate, onComplete) => ({
  type: LOAD_MOST_POPULAR_CATEGORIES,
  navigate,
  onComplete
});

export const loadPersonalNominations = (userId, navigate, onComplete) => ({
  type: LOAD_PERSONAL_NOMINATIONS,
  userId,
  onComplete,
  navigate
});

export const loadActiveCategories = (navigate, onComplete) => ({
  type: LOAD_CATEGORIES,
  navigate,
  onComplete
});

export const createKudosNomination = (payload, navigate, onSuccess, onError) => ({
  type: CREATE_KUDOS_NOMINATION,
  payload,
  navigate,
  onSuccess,
  onError
});

export const clearKudosErrors = () => ({
  type: CLEAR_KUDOS_ERRORS
});

export const addRealtimePersonalNomination = (nomination) => ({
  type: ADD_REALTIME_PERSONAL_NOMINATION,
  nomination
});

export const addRealtimeNomination = (nomination) => ({
  type: ADD_REALTIME_NOMINATION,
  nomination
});

export const addRealtimeNominationMessageRemove = (nominationEvent) => ({
  type: ADD_REALTIME_NOMINATION_MESSAGE_REMOVE,
  nominationEvent
});

export const addRealtimeNominationDelete = (nominationEvent) => ({
  type: ADD_REALTIME_NOMINATION_DELETE,
  nominationEvent
});

export const setRealTimeRemainingPoints = (remainingPoints) => ({
  type: SET_REALTIME_REMAINING_POINTS,
  remainingPoints
});

export const removeNominationMessage = (nominationId, user, navigate, onComplete) => ({
  type: REMOVE_NOMINATION_MESSAGE,
  nominationId,
  user,
  navigate,
  onComplete
});

export const toggleLike = (nominationId, likerId, navigate, onComplete, onFail) => ({
  type: TOGGLE_LIKE,
  nominationId,
  likerId,
  navigate,
  onComplete,
  onFail
});
