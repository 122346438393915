import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/utils';

import { clearKudosErrors } from '../state/action/kudos/kudos.actions';
import {
  createKudosCategory,
  loadKudosManagementCategories,
  updateCategory
} from '../state/action/kudos/management.actions';

import { KudosManagementContext } from '../context/KudosManagement.context';

import { useProgressLoader } from '../hook/useProgressLoader';

const blankCategory = {
  name: '',
  description: '',
  colorCode: '#00D849',
  textColorCode: '#000',
  points: 0
};

const KudosCategoryManagementProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryToBeUpdated, setCategoryToBeUpdated] = useState();
  const [categoryToBeSaved, setCategoryToBeSaved] = useState(blankCategory);

  const [categoryFilter, setCategoryFilter] = useState('active');

  const memoizedIsActive = useMemo(() => categoryFilter === 'active', [categoryFilter]);

  const { categories, errors, kudosStateLoading } = useSelector(
    ({ kudos_store, profile_store, directory_store, kudos_management_store }) => ({
      categories: kudos_management_store.categories,
      errors: kudos_store.errors,
      kudosStateLoading: kudos_management_store.loading
    }),
    //Prevent re-render on each useSelector call
    shallowEqual
  );

  const [isCategoriesLoading, setIsCategoriesLoading] = useState(kudosStateLoading ?? true);

  const fetchCategories = () => {
    dispatch(
      loadKudosManagementCategories(
        navigate,
        () => setIsCategoriesLoading(false),
        !memoizedIsActive
      )
    );
  };

  useEffect(() => {
    if (isEmpty(categories)) {
      fetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter]);

  const onUpdateCategory = (categoryId, data, onSuccess) => {
    setCategoryLoading(true);
    dispatch(
      updateCategory(categoryId, data, navigate, onSuccess, () => setCategoryLoading(false))
    );
  };

  const resetCategory = () => setCategoryToBeSaved(blankCategory);

  const handleCreateCategorySuccess = () => {
    resetCategory();
    setCategoryLoading(false);
    setIsModalOpen(false);
    setBusyState(false);
    fetchCategories();
  };

  const onCreateCategory = (data) => {
    setBusyState(true);
    setCategoryToBeSaved(data);
    setCategoryLoading(true);
    dispatch(
      createKudosCategory(data, navigate, handleCreateCategorySuccess, () => {
        setCategoryLoading(false);
        setBusyState(false);
      })
    );
  };

  const clearErrors = useCallback(() => {
    dispatch(clearKudosErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, clearErrors]);

  const value = {
    loading: isCategoriesLoading,
    setIsCategoriesLoading,
    categories,
    categoryFilter,
    setCategoryFilter,
    isModalOpen,
    setIsModalOpen,
    selectedCategory,
    setSelectedCategory,
    fetchCategories,
    categoryLoading,
    onUpdateCategory,
    categoryToBeUpdated,
    setCategoryToBeUpdated,
    resetCategory,
    onCreateCategory,
    categoryToBeSaved,
    errors,
    clearErrors
  };

  return (
    <KudosManagementContext.Provider value={value}>{children}</KudosManagementContext.Provider>
  );
};

export default KudosCategoryManagementProvider;
