import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { RESPONSE_STATUS } from '../../tool/constant';
import { mapErrors } from '../../tool/error.util';
import {
  getAddUserRequest,
  getChangeUserRoleRequest,
  getLoadUsersRequest,
  getRemoveUserRequest
} from '../tool/api/user.endpoint';

import { logout } from '../action/auth.actions';
import { addSystemNotice } from '../action/system.actions';
import {
  ADD_USER,
  CHANGE_USER_ROLE,
  LOAD_USERS,
  REMOVE_USER,
  SET_ERRORS,
  SET_USER,
  SET_USERS,
  loadUsers
} from '../action/user.action';

export function* performLoadUsers({ onComplete, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadUsersRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USERS, users: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The users could not be found.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadUsers() {
  yield takeLeading(LOAD_USERS, performLoadUsers);
}

export function* performAddUser({ payload, navigate, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAddUserRequest(payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USER, user: data });

    yield put(
      addSystemNotice(
        `${payload.firstName} ${payload.lastName} was added successfully`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(navigate, onSuccess));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put({ type: SET_ERRORS, errors: mapErrors(response.data) });
    yield put(addSystemNotice('The user could not be added.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddUser() {
  yield takeLeading(ADD_USER, performAddUser);
}

export function* performRemoveUser({ user, navigate, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveUserRequest(user?.id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName} successfully removed from Neslo.`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(navigate, onComplete));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The user could not be removed.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveUser() {
  yield takeLeading(REMOVE_USER, performRemoveUser);
}

export function* performChangeUserRole({ user, navigate, onComplete }) {
  try {
    const payload = { role: user.role };
    // get endpoint and http request options
    const [endpoint, requestOptions] = getChangeUserRoleRequest(user?.id, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName}'s role was changed successfully.`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(navigate, onComplete));
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(
      addSystemNotice(
        `An error occurred while attempting to change ${user.firstName} ${user.lastName}'s role. Please try again.`,
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForPerformChangeUserRole() {
  yield takeLeading(CHANGE_USER_ROLE, performChangeUserRole);
}

export default function* userSaga() {
  yield all([
    watchForLoadUsers(),
    watchForAddUser(),
    watchForRemoveUser(),
    watchForPerformChangeUserRole()
  ]);
}
