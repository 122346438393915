import { arrayOf, bool } from 'prop-types';
import React, { createRef, useEffect, useMemo } from 'react';

import { getClassNames } from '@neslotech/utils';

import { FILTER_OPTIONS } from '../../../employee-directory/employee-directory.helper';

import { Tooltip } from '../../../../common/layout/portal/tooltip/Tooltip';
import { USER_AVATAR_ALT } from '../../../../tool/constant';
import { filterElement } from '../../../../tool/filter.util';
import { USER_TYPE } from '../../../../tool/prop-types';

import { useFilters } from '../../../../hook/useFilter';

import { Dropdown } from '../../../../common/component/dropdown/Dropdown';
import { FiltersWrapper } from '../../../../common/component/filter/FiltersWrapper';
import NoResults from '../../../../common/component/no-results/NoResults';

import { ReactComponent as ChevronIcon } from '../../../../icon/chevron-icon.svg';

import { SchedulingCalendarView } from './view/SchedulingCalendarView';

import './scheduling-calendar.scss';

export const SchedulingCalendar = ({ users = [], personal = false }) => {
  const { filters, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredUsers = useMemo(
    () => users.filter((user) => filterElement(user, filters)),
    [users, filters]
  );

  const tooltipRefs = useMemo(() => {
    if (filteredUsers) {
      return filteredUsers.map(() => createRef());
    }

    return [];
  }, [filteredUsers]);

  return (
    <section className="scheduling-calendar">
      <div className="scheduling-calendar__members">
        <header>
          <Dropdown dropdownChildren={<FiltersWrapper />} placement="bottom-start">
            <div className="scheduling-calendar__filter">
              <span>Filter</span>
              <ChevronIcon />
            </div>
          </Dropdown>
          <span className="scheduling-calendar__members--time">25.2h</span>
        </header>
        {filteredUsers.length === 0 ? (
          <div className="scheduling-calendar__empty-state">
            <NoResults
              emptyTitle="There were no results found"
              emptySubtitle="Try removing some filters to see more results."
            />
          </div>
        ) : (
          filteredUsers.map((user, index) => (
            <div
              className={getClassNames('scheduling-calendar__user-info', { personal })}
              key={user.id}
            >
              <img referrerPolicy="no-referrer" src={user.image} alt={USER_AVATAR_ALT} />
              <div>
                <span className="scheduling-calendar__name">{`${user.firstName} ${user.lastName}`}</span>
                <span className="scheduling-calendar__department">{user.department}</span>
                <span className="scheduling-calendar__job-title">{user.jobTitle}</span>
              </div>
              <span className="scheduling-calendar__members--time" ref={tooltipRefs[index]}>
                25.2h
              </span>
              <Tooltip parent={tooltipRefs[index]} offset={60}>
                <div className="scheduling-calendar__tooltip">
                  <div>
                    <span
                      // TODO: Change this color when time calculations are implemented
                      style={{ color: '#EB5D3E' }}
                    >
                      25h
                    </span>
                    <span>Scheduled</span>
                  </div>

                  <div>
                    <span>7h</span>
                    <span>Leave</span>
                  </div>
                </div>
              </Tooltip>
            </div>
          ))
        )}
      </div>
      <SchedulingCalendarView users={filteredUsers} personal={personal} />
    </section>
  );
};

SchedulingCalendar.propTypes = {
  users: arrayOf(USER_TYPE),
  personal: bool
};
