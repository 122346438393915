import { addDays, differenceInDays, format, isWeekend, startOfToday } from 'date-fns';
import { arrayOf, bool } from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';

import { getClassNames, isEmpty } from '@neslotech/utils';

import { USER_TYPE } from '../../../../../tool/prop-types';

import './scheduling-calendar-view.scss';

export const SchedulingCalendarView = ({ users = Array.from({ length: 6 }), personal = false }) => {
  const calendarRef = useRef(null);

  // This will need to change to the earliest date a user was created
  const startDate = new Date(2023, 0, 8);

  // added 90 to reflect 3 months from today so the user can scroll until 3 months from today
  const totalDays = differenceInDays(new Date(), startDate) + 90;

  const daysOfWeek = Array.from({ length: totalDays }, (_, i) => addDays(startDate, i));

  useEffect(() => {
    const today = startOfToday();
    const todayIndex = daysOfWeek.findIndex((day) => day.toDateString() === today.toDateString());
    const scrollElement = calendarRef.current;

    if (scrollElement) {
      //used -2 because there are two elements hidden in the scheduling calendar members
      const todayElement = scrollElement.children[todayIndex - 2];
      if (todayElement) {
        //used -50 on the offset because todayElement.offsetLeft is taking half the element
        scrollElement.scrollLeft = todayElement.offsetLeft - 50;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userList = useMemo(() => (isEmpty(users) ? Array.from({ length: 6 }) : users), [users]);

  return (
    <section className="scheduling-calendar__view">
      <div className="scheduling-calendar__week" ref={calendarRef}>
        {daysOfWeek.map((day) => (
          <div key={day}>
            <div className={getClassNames('scheduling-calendar__day', { weekend: isWeekend(day) })}>
              <span>{format(day, 'd')}</span>
              <span>{format(day, 'cccc')}</span>
            </div>
            {userList.map((user, index) => (
              <div
                key={user?.id ?? index}
                className={getClassNames('scheduling-calendar__tasks', {
                  weekend: isWeekend(day),
                  personal
                })}
              />
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

SchedulingCalendarView.propTypes = {
  users: arrayOf(USER_TYPE),
  personal: bool
};
