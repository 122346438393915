import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { RESPONSE_STATUS } from '../../../tool/constant';
import { mapErrors } from '../../../tool/error.util';
import { getLoadCategoriesRequest } from '../../tool/api/kudos/kudos.endpoint';
import {
  getArchiveCategoryRequest,
  getCreateCategoryRequest,
  getReinstateCategoryRequest,
  getUpdateCategoryRequest
} from '../../tool/api/kudos/management.endpoint';

import { logout } from '../../action/auth.actions';
import { SET_CATEGORIES, SET_KUDOS_ERRORS } from '../../action/kudos/kudos.actions';
import {
  ARCHIVE_KUDOS_CATEGORY,
  CREATE_KUDOS_CATEGORY,
  LOAD_KUDOS_CATEGORIES,
  REINSTATE_KUDOS_CATEGORY,
  SET_KUDOS_CATEGORIES,
  UPDATE_KUDOS_CATEGORY
} from '../../action/kudos/management.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performArchiveCategory({ categoryId, navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getArchiveCategoryRequest(categoryId);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The kudos category has been archived successfully.', SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos category could not be archived!', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForArchiveCategory() {
  yield takeLeading(ARCHIVE_KUDOS_CATEGORY, performArchiveCategory);
}

export function* performReinstateCategory({ categoryId, navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getReinstateCategoryRequest(categoryId);

    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice('The kudos category has been reinstated successfully.', SNACK_SUCCESS)
    );
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos category could not be reinstated!', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForReinstateCategory() {
  yield takeLeading(REINSTATE_KUDOS_CATEGORY, performReinstateCategory);
}

export function* performUpdateCategory({ categoryId, payload, navigate, onSuccess, onComplete }) {
  try {
    const [endpoint, requestOptions] = getUpdateCategoryRequest(categoryId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`${payload.name} changes have been saved.`, SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });

    yield call(onSuccess);
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    if (response?.status === RESPONSE_STATUS.CONFLICT) {
      const error = 'A category with this name already exists';
      yield put(addSystemNotice(error, SNACK_CRITICAL));
      yield put({ type: SET_KUDOS_ERRORS, errors: { name: error } });
    } else {
      yield put(addSystemNotice('The kudos category could not be updated!', SNACK_CRITICAL));
    }
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateCategory() {
  yield takeLeading(UPDATE_KUDOS_CATEGORY, performUpdateCategory);
}

export function* performCreateCategory({ payload, navigate, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getCreateCategoryRequest(payload);

    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }

    yield put(addSystemNotice(`${payload.name} created successfully.`, SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    if (response?.status === RESPONSE_STATUS.CONFLICT) {
      const error = 'A category with this name already exists';
      yield put(addSystemNotice(error, SNACK_CRITICAL));
      yield put({ type: SET_KUDOS_ERRORS, errors: { name: error } });
    } else {
      yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });
      yield put(addSystemNotice('The kudos category could not be created!', SNACK_CRITICAL));
    }

    if (onError) {
      yield call(onError);
    }
  }
}

export function* watchForCreateCategory() {
  yield takeLeading(CREATE_KUDOS_CATEGORY, performCreateCategory);
}

export function* performLoadKudosCategories({ navigate, archived, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadCategoriesRequest(archived);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_KUDOS_CATEGORIES, categories: data });
  } catch ({ response }) {
    if (
      response?.status === RESPONSE_STATUS.FORBIDDEN ||
      response?.status === RESPONSE_STATUS.UNAUTHORIZED
    ) {
      yield put(logout(navigate));
    }

    yield put(addSystemNotice('The kudos categories data could not be loaded!', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadKudosCategories() {
  yield takeLeading(LOAD_KUDOS_CATEGORIES, performLoadKudosCategories);
}

export default function* managementSaga() {
  yield all([
    watchForArchiveCategory(),
    watchForReinstateCategory(),
    watchForUpdateCategory(),
    watchForCreateCategory(),
    watchForLoadKudosCategories()
  ]);
}
