import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearKudosErrors, createKudosNomination } from '../state/action/kudos/kudos.actions';

import { KudosContext } from '../context/Kudos.context';

import { useProgressLoader } from '../hook/useProgressLoader';

const KudosProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setBusyState, setFullScreen } = useProgressLoader();

  const [form, setForm] = useState({});

  const remainingPoints = useSelector(({ kudos_store }) => kudos_store.remainingPoints);
  const categories = useSelector(({ kudos_store }) => kudos_store.categories);
  const errors = useSelector(({ kudos_store }) => kudos_store.errors);

  const [remaining, setRemaining] = useState(remainingPoints ?? 0);

  const disabledCategories = useMemo(
    () => categories.filter((item) => item.points > remainingPoints).map((item) => item.id),
    [categories, remainingPoints]
  );

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  useEffect(() => {
    setRemaining(remainingPoints);
  }, [remainingPoints]);

  const onChange = (newState) => {
    dispatch(clearKudosErrors());

    setForm({ ...form, ...newState });
    if (isNaN(newState.categoryPoints)) {
      return;
    }

    setRemaining(remainingPoints - newState.categoryPoints);
  };

  const handleGiveKudosSuccess = (onClose) => {
    onClose();
    setBusyState(false);
  };

  const onSubmit = (onClose) => {
    setBusyState(true);
    dispatch(
      createKudosNomination(
        form,
        navigate,
        () => handleGiveKudosSuccess(onClose),
        () => setBusyState(false)
      )
    );
  };

  const value = {
    form,
    setForm,
    onChange,
    remaining,
    errors,
    onSubmit,
    disabledCategories
  };

  return <KudosContext.Provider value={value}>{children}</KudosContext.Provider>;
};

export default KudosProvider;
