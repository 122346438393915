import { noOp } from '@neslotech/utils';

import profileIcon from '../icon/profile-icon.svg';

import { ROLES } from './constant';

export const MOCK_USER = {
  id: '123',
  active: true,
  firstName: 'Bob',
  secondName: 'The',
  lastName: 'Builder',
  image: 'https://some-img-url-that-does-not-exist.com',
  department: 'Creative Director',
  jobTitle: 'java developer',
  role: ROLES.EMPLOYEE
};

export const MOCK_NOTIFICATION = {
  id: '1',
  recipientId: '1',
  category: '1',
  message: '<span>Stanley Maraba</span> nominated you for',
  createdBy: '123',
  createdDate: '2023-05-31T22:00:00Z',
  read: false,
  profileImageSrc: 'default-profile-image-placeholder.png',
  timeSent: '2 hours ago',
  receiverId: '1',
  senderId: '2',
  type: 'KUDOS',
  metadata: {
    nominationId: '1',
    metadataType: 'NOMINATION_METADATA',
    recipientId: '123'
  }
};

export const MOCK_NOMINATION = {
  id: '1',
  profileImageSrc: 'default-profile-image-placeholder.png',
  sender: 'Tristan Carswell',
  receiver: 'Stanley Maraba',
  receiverId: '1',
  senderId: '2',
  date: '7/12/2023 9:51:26 AM',
  category: {
    name: 'Team Player',
    colorCode: '#eb5d3e',
    textColorCode: '#fff',
    points: 40
  },
  timeSent: '20 min ago',
  message: 'I appreciate your dedication to being a team player.',
  totalLikes: 0,
  totalComments: 1
};

export const MOCK_NOMINATION_COMMENT = {
  id: '1',
  nominationId: '2',
  profileImageSrc: 'https://lh3.googleusercontent.com/a-/AD_cMMQMy2M1o8LRn3OYPlQYm9Wqan',
  sender: 'Stanley Maraba',
  message: 'this is a message',
  createdBy: '3',
  totalReplies: 1,
  parentCommentId: '1'
};

export const MOCK_CATEGORY = {
  id: '6',
  name: 'Just keep swimming',
  description: 'Do as you always do',
  value: 40,
  points: 40,
  colorCode: '#EB5D3E',
  frequency: 1,
  active: true
};

export const MOCK_PROFILE = {
  id: '123456',
  fullName: 'John The Baptist',
  role: ROLES.ADMIN,
  defaultImage: '/default-profile-image-placeholder.png',
  jobTitle: 'Mechanic',
  department: 'Engineers',
  email: 'jbaptist@neslotech.co.za',
  phoneNumber: '1234567890',
  emergencyContact: 'Jesus',
  joinedOn: '2023-05-31T22:00:00Z',
  emergencyContactNumber: '0987654321',
  bio:
    'I got started with my digital design career through my studies in Creative Brand Communication. With multi-disciplinary ' +
    'experiences every day is unique',
  birthday: '2022-11-12',
  socialLinks: [
    {
      id: '1234567890',
      type: 'GIT_HUB',
      link: 'https://github.com/some-link-that-does-not-exist'
    },
    {
      id: '1234567890',
      type: 'LINKED_IN',
      link: 'https://linkedin.com/some-link-that-does-not-exist'
    }
  ]
};

export const MOCK_CLIENT = {
  id: '1',
  name: 'Neslo',
  colour: '#000',
  deleted: false,
  projects: [
    { id: '1', name: 'project 1' },
    { id: '2', name: 'project 2' },
    { id: '3', name: 'project 3' }
  ]
};

export const MOCK_PROJECT = {
  id: '1',
  name: 'Test Project',
  client: { name: 'Internal' },
  colour: '#000',
  budget: 10000,
  startDate: '2024/01/01',
  endDate: '2024/12/01',
  deleted: false
};

export const MOCK_LEAVE_CATEGORY = {
  id: '1',
  name: 'mango category',
  colour: '#FF8080',
  deleted: false
};

export const PLACEHOLDER_USERS = [{ id: '1' }, { id: '2' }, { id: '3' }];

export const PLACEHOLDER_PROJECTS = [
  { id: '1', name: 'Project 1', startDate: '2024/01/01', colour: '#000' },
  { id: '2', name: 'Project 2', startDate: '2024/01/01', colour: '#000' },
  { id: '3', name: 'Project 3', startDate: '2024/01/01', colour: '#000' }
];

export const MOCK_PROGRESS_LOADER_CONTEXT = {
  setBusyState: noOp,
  setFullScreen: noOp,
  busy: false
};

export const MOCK_DESKTOP_DEVICE_CONTEXT = { mobile: false };
export const MOCK_MOBILE_DEVICE_CONTEXT = { mobile: true };

export const MOCK_JIRA_RESPONSE = [
  {
    id: 'project-1',
    name: 'Vacutech',
    colour: '#E2D3F2',
    tasks: [
      {
        id: 'task-1',
        summary: 'Fix login issue',
        name: 'NES-103',
        profileImageSrc: profileIcon
      },
      {
        id: 'task-2',
        summary: 'Update user profile page',
        name: 'NES-403',
        profileImageSrc: profileIcon
      }
    ]
  },
  {
    id: 'project-2',
    name: 'Neslo App',
    colour: '#CEEBFD',
    tasks: [
      {
        id: 'NES-104',
        summary: 'As a [Designer] i would like to do the SWOT analysis for the Vacutech website',
        name: 'NES-9000',
        profileImageSrc: profileIcon
      },
      {
        id: 'task-4',
        summary: 'As a [Designer] i would like to do the SWOT analysis for the Vacutech website',
        name: 'NES-500',
        profileImageSrc: profileIcon
      }
    ]
  }
];

export const MOCK_USERS = [
  {
    id: '1',
    defaultImage:
      'https://lh3.googleusercontent.com/a/AEdFTp4CC3onmEx8USaQjEPiR6EweFiFW7CJp5hwE9ZO=s500',
    person: {
      firstName: 'Bob',
      lastName: 'Builder',
      department: 'Creative Director',
      jobTitle: 'java developer'
    },
    email: 'bbuilder@neslotech.co.za',
    department: 'Engineers',
    role: ROLES.EMPLOYEE
  },
  {
    id: '2',
    defaultImage:
      'https://lh3.googleusercontent.com/a/AEdFTp4CC3onmEx8USaQjEPiR6EweFiFW7CJp5hwE9ZO=s500',
    person: {
      firstName: 'Steve',
      lastName: 'Wonder',
      department: 'Creative Director',
      jobTitle: 'graphic designer'
    },
    email: 'swonder@neslotech.co.za',
    department: 'Management',
    role: ROLES.ADMIN
  }
];
