import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loadBasicProfile } from '../../../../../state/action/profile.actions';
import { addUser } from '../../../../../state/action/user.action';

import { useAuth } from '../../../../../hook/useAuth';
import { useProgressLoader } from '../../../../../hook/useProgressLoader';

import AppLayout from '../../../../../component/layout/AppLayout';
import AddUser from '../../../../../component/user/management/add/AddUser';

const serverify = (form) => ({
  username: form.username,
  role: form.role,
  alternateEmailAddress: form.alternateEmail,
  firstName: form.firstName,
  lastName: form.lastName
});

const MobileAddUserContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useAuth();

  const basicProfile = useSelector(({ profile_store }) => profile_store.basicProfile);
  const errors = useSelector(({ user_store }) => user_store.errors);

  const { setBusyState } = useProgressLoader();

  useEffect(() => {
    if (!basicProfile) {
      dispatch(loadBasicProfile(id, navigate));
    }
  }, [basicProfile, id, dispatch, navigate]);

  const handleAddClick = (form) => {
    setBusyState(true);
    const payload = serverify(form);

    dispatch(
      addUser(
        payload,
        navigate,
        () => navigate(-1),
        () => setBusyState(false)
      )
    );
  };

  return (
    <AppLayout profile={basicProfile} showBreadcrumb={false}>
      <AddUser errors={errors} onAddClick={handleAddClick} />
    </AppLayout>
  );
};

export default MobileAddUserContainer;
