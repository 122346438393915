import { array, arrayOf, bool, elementType, func, oneOfType, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import InputError from '../../../common/component/input/error/InputError';

import { ReactComponent as EditIcon } from '../../../icon/edit-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';

import './colour-select.scss';

const ColourItem = ({ colour, children = undefined, onClick, isSelected }) => (
  <div
    className={getClassNames('colour-select__radio-group', {
      selected: isSelected
    })}
    style={{
      '--border-colour': isSelected ? colour.borderColour ?? colour.colour : 'transparent',
      backgroundColor: colour.colour,
      border: `solid 1px ${colour.borderColour ?? colour.colour}`
    }}
    onKeyDown={noOp}
    onClick={onClick}
  >
    {children}
  </div>
);

ColourItem.propTypes = {
  colour: shape({
    colour: string.isRequired,
    borderColour: string
  }).isRequired,
  children: oneOfType([elementType, array]),
  onClick: func.isRequired,
  isSelected: bool.isRequired
};

const ColourSelect = ({
  value = '',
  label = '',
  colours,
  error = '',
  disabled = false,
  showColourPicker = false,
  onSelectionChange = noOp,
  colourPickerButtonColour = '#000',
  customColour = '',
  required = false
}) => {
  const [currentColour, setCurrentColour] = useState(customColour);
  const [defaultColour, setDefaultColour] = useState(colourPickerButtonColour.colour);
  const [selectedColourOption, setSelectedColourOption] = useState(customColour);

  const hasCustomColour = customColour && !colours.some((colour) => colour.colour === customColour);

  const colourExistsInList = (colour) => colours.some((c) => c.colour === colour);

  const handleOnClick = (colour) => {
    if (disabled) {
      return;
    }

    onSelectionChange(colour);

    if (colours.every((c) => c.colour !== colour)) {
      setCurrentColour(colour);
      setDefaultColour(colour);
      setSelectedColourOption(customColour);
    } else {
      setSelectedColourOption(colour);
      setCurrentColour(customColour);
      setDefaultColour(colourPickerButtonColour.colour);
    }
  };

  const colourPicker = useMemo(() => {
    return (
      <>
        {customColour &&
          colours.map((item) => (
            <ColourItem
              key={`colour_${item.colour}`}
              colour={item}
              onClick={() => handleOnClick(item.colour)}
              isSelected={
                hasCustomColour
                  ? value === item.colour
                  : selectedColourOption === item.colour && colourExistsInList(currentColour)
              }
            />
          ))}
        {showColourPicker && colours && <div className="colour-select__item-container" />}

        {showColourPicker && !hasCustomColour && (
          <ColourItem
            colour={{ colour: defaultColour }}
            isSelected={currentColour === defaultColour}
            onClick={() => handleOnClick(colourPickerButtonColour.colour)}
          >
            {defaultColour === colourPickerButtonColour.colour && <PlusIcon />}
            <input
              type="color"
              value={value}
              disabled={disabled}
              onChange={(e) => handleOnClick(e.target.value)}
            />
          </ColourItem>
        )}

        {showColourPicker && customColour && hasCustomColour && (
          <ColourItem
            colour={{ colour: currentColour }}
            isSelected={!colours.some((colour) => colour.colour === value)}
            onClick={() => handleOnClick(customColour)}
          >
            {!colours.some((colour) => colour.colour === value) && <EditIcon />}
            <input
              type="color"
              value={value}
              disabled={disabled}
              onChange={(e) => handleOnClick(e.target.value)}
            />
          </ColourItem>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    colours,
    customColour,
    defaultColour,
    showColourPicker,
    currentColour,
    value,
    disabled,
    colourPickerButtonColour,
    handleOnClick,
    hasCustomColour,
    selectedColourOption
  ]);

  return (
    <div className="colour-select">
      <span className="colour-select__label">{required ? `${label}*` : label}</span>
      <div className="colour-select__field-container">
        <fieldset>
          {!customColour &&
            colours.map((item) => (
              <ColourItem
                key={`colour_${item.colour}`}
                colour={item}
                onClick={() => handleOnClick(item.colour)}
                isSelected={
                  item.colour === selectedColourOption &&
                  item.colour &&
                  !colourExistsInList(currentColour)
                }
              />
            ))}
          {colourPicker}
        </fieldset>
      </div>
      {error && <InputError error={error} />}
    </div>
  );
};

ColourSelect.prototype = {
  value: string,
  label: string,
  colours: arrayOf(
    shape({
      colour: string,
      borderColour: string
    })
  ).isRequired,
  colourPickerButtonColour: string,
  error: string,
  disabled: bool,
  showColourPicker: bool,
  onSelectionChange: func,
  customColour: string,
  required: bool
};

export default ColourSelect;
