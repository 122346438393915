import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFormState } from '@neslotech/hooks';
import { SNACK_SUCCESS, isEmpty } from '@neslotech/utils';

import { EDIT_PROFILE_TAB_OPTIONS, NONE, PROFILE_TABS, SOCIAL_TYPE } from '../tool/constant';
import { toBase64 } from '../tool/file.util';

import { clearErrors, updateProfileInfo } from '../state/action/profile.actions';
import { addSystemNotice } from '../state/action/system.actions';

import { ProfileContext } from '../context/Profile.context';

import { useProgressLoader } from '../hook/useProgressLoader';

const formifyPersonalInfo = (person) => ({
  firstName: person?.firstName ?? '',
  secondName: person?.secondName ?? '',
  lastName: person?.lastName ?? '',
  initials: person?.initials ?? '',
  gender: person?.gender ?? '',
  alternativeEmail: person?.contact?.alternateEmailAddress ?? '',
  birthday: !!person?.dateOfBirth ? new Date(person?.dateOfBirth) : '',
  phoneNumber: person?.contact?.phoneNumber ?? '',
  jobTitle: person?.jobTitle ?? '',
  department: person?.department ?? '',
  identityNumber: person?.idNumber ?? '',
  taxNumber: person?.taxNumber ?? '',
  passportNumber: person?.passportNumber ?? '',
  passportCountry: person?.passportCountry ?? '',
  lineManager: person?.lineManager?.id ?? NONE,
  bio: person?.personalBio ?? ''
});

const formifyAddress = (address) => ({
  id: address?.id,
  buildingName: address?.buildingName ?? '',
  streetAddress: address?.streetAddress ?? '',
  suburb: address?.suburb ?? '',
  city: address?.city ?? '',
  postalCode: address?.postalCode ?? ''
});

const formifyBankDetails = (bankDetails) => ({
  id: bankDetails?.id,
  bankName: bankDetails?.bankName ?? '',
  accountType: bankDetails?.accountType ?? '',
  accountHolder: bankDetails?.accountHolder ?? '',
  accountNumber: bankDetails?.accountNumber ?? '',
  branchCode: bankDetails?.branchCode ?? ''
});

const formifyEmergencyDetails = (emergencyDetails) => ({
  id: emergencyDetails?.id,
  contactName: emergencyDetails?.contactName ?? '',
  contactNumber: emergencyDetails?.contactNumber ?? '',
  medicalAid: emergencyDetails?.medicalAid ?? '',
  medicalAidNumber: emergencyDetails?.medicalAidNumber ?? '',
  medicalAidOption: emergencyDetails?.medicalAidOption ?? '',
  dependantCode: emergencyDetails?.dependantCode ?? '',
  policyHolder: emergencyDetails?.policyHolder ?? ''
});

const formifySocialLinks = (socialLinks = []) =>
  socialLinks.reduce((accum, socialLink) => {
    switch (socialLink.type) {
      case SOCIAL_TYPE.LINKED_IN:
        return {
          ...accum,
          linkedIn: socialLink.link
        };
      case SOCIAL_TYPE.BEHANCE:
        return {
          ...accum,
          behance: socialLink.link
        };
      case SOCIAL_TYPE.GIT_HUB:
        return {
          ...accum,
          github: socialLink.link
        };
      default:
        throw new Error('The type is unknown');
    }
  }, {});

const formify = (profile) => {
  const person = profile?.person;

  return {
    personalInfo: formifyPersonalInfo(person),
    physicalAddress: formifyAddress(person?.contact?.residentialAddress),
    bankDetails: formifyBankDetails(person?.bankingDetails),
    emergencyContact: formifyEmergencyDetails(person?.emergencyDetails),
    image: profile?.image,
    defaultImage: profile?.defaultImage,
    socialLinks: formifySocialLinks(profile?.person?.contact?.socialLinks ?? profile?.socialLinks)
  };
};

const rules = {
  validates: {
    personalInfo: ['validPersonalInfo'],
    physicalAddress: ['validPhysicalAddress'],
    bankDetails: ['validBankDetails'],
    emergencyContact: ['validEmergencyContact']
  },

  validPersonalInfo(form) {
    if (
      isEmpty(form.firstName) ||
      isEmpty(form.lastName) ||
      isEmpty(form.alternativeEmail) ||
      isEmpty(form.phoneNumber) ||
      isEmpty(form.jobTitle) ||
      isEmpty(form.identityNumber) ||
      isEmpty(form.taxNumber)
    ) {
      return 'Personal info details are invalid';
    }
  },
  validPhysicalAddress(form) {
    if (
      isEmpty(form.streetAddress) ||
      isEmpty(form.suburb) ||
      isEmpty(form.city) ||
      isEmpty(form.postalCode)
    ) {
      return 'Physical address details are invalid';
    }
  },
  validBankDetails(form) {
    if (
      isEmpty(form.bankName) ||
      isEmpty(form.accountType) ||
      isEmpty(form.accountHolder) ||
      isEmpty(form.accountNumber) ||
      isEmpty(form.branchCode)
    ) {
      return 'Bank details are invalid';
    }
  },
  validEmergencyContact(form) {
    if (isEmpty(form.contactName) || isEmpty(form.contactNumber)) {
      return 'Emergency details are invalid';
    }
  }
};

const EditProfileProvider = ({ children, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const errors = useSelector(({ profile_store }) => profile_store.errors);

  const memoizedForm = useMemo(() => {
    return formify(profile);
  }, [profile]);

  const [form, setForm] = useFormState(formify(profile), rules);
  const [selectedTab, setSelectedTab] = useState(PROFILE_TABS.PERSONAL_INFO);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!profile) {
      setForm(formify(profile));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [profile]);

  const onChange = (newState) => setForm({ ...form, ...newState });

  const stopLoading = () => {
    setLoading(false);
    setBusyState(false);
  };

  const onSuccess = () => {
    setBusyState(false);
    dispatch(
      addSystemNotice('Your profile information has been updated successfully.', SNACK_SUCCESS)
    );
  };

  const onNextClick = () => {
    setBusyState(true);
    dispatch(updateProfileInfo(userId, form, selectedTab, navigate, onSuccess, stopLoading));
    dispatch(clearErrors());
  };

  const onBackClick = () => {
    const tabValues = Object.values(PROFILE_TABS);

    const prevTabIndex = tabValues.indexOf(selectedTab) - 1;
    setSelectedTab(tabValues[prevTabIndex]);
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
  };

  const showUndo = () =>
    JSON.stringify(form[selectedTab]) !== JSON.stringify(memoizedForm[selectedTab]);

  const onUndoChangesClick = () => {
    onChange({ [selectedTab]: memoizedForm[selectedTab] });
    dispatch(clearErrors());
  };

  const value = {
    form,
    editMode: true,
    showUndo,
    tabs: EDIT_PROFILE_TAB_OPTIONS,
    selectedTab,
    setSelectedTab,
    loading,
    errors,
    onChange,
    onBackClick,
    onNextClick,
    handleFileChange,
    onUndoChangesClick
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default EditProfileProvider;
