import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROLES } from '../../tool/constant';

import { loadDirectoryUsers } from '../../state/action/directory.actions';
import { loadAllProjects } from '../../state/action/scheduling/project.actions';
import { loadUsers } from '../../state/action/user.action';

import AdminDashboardContainer from './AdminDashboard.container';
import EmployeeDashboardContainer from './EmployeeDashboard.container';

import AppLayout from '../../component/layout/AppLayout';

const DashboardContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(
    ({ profile_store }) => profile_store.contextUser ?? profile_store.profile
  );

  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const users = useSelector(({ user_store }) => user_store.users);
  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);
  const projects = useSelector(({ project_store }) => project_store.projects);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    if (profile?.role === ROLES.ADMIN) {
      dispatch(loadUsers(navigate, () => setLoading(false)));
      dispatch(loadAllProjects(navigate));
    }
    dispatch(loadDirectoryUsers(navigate, () => setLoading(false)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const renderDashboard = () => {
    if (profile?.role === ROLES.ADMIN) {
      return (
        <AdminDashboardContainer
          profile={profile}
          users={users}
          directoryUsers={directoryUsers}
          projects={projects}
          loading={loading}
        />
      );
    }

    return (
      <EmployeeDashboardContainer
        profile={profile}
        directoryUsers={directoryUsers}
        loading={loading}
      />
    );
  };

  return <AppLayout showBreadcrumb={false}>{renderDashboard()}</AppLayout>;
};

export default DashboardContainer;
