import { arrayOf, element, func, oneOf, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getClassNames } from '@neslotech/utils';

import { ROLES } from '../../../tool/constant';

import { ReactComponent as BufferIcon } from '../../../icon/buffer.icon.svg';
import { ReactComponent as CalendarIcon } from '../../../icon/calendar-solid-icon.svg';
import { ReactComponent as ChartIcon } from '../../../icon/chart-bar-icon.svg';
import { ReactComponent as CogIcon } from '../../../icon/cog-icon.svg';
import { ReactComponent as UsersIcon } from '../../../icon/user-friends-icon.svg';

import './scheduling-menu.scss';

const topMenuItems = [
  {
    icon: <CalendarIcon />,
    path: '/scheduling/dashboard',
    allowedRoles: [ROLES.ADMIN],
    name: 'Schedule'
  },
  {
    icon: <UsersIcon />,
    path: '/scheduling/clients',
    allowedRoles: [ROLES.ADMIN],
    name: 'Clients'
  },
  {
    icon: <BufferIcon />,
    path: '/scheduling/projects',
    allowedRoles: [ROLES.ADMIN],
    name: 'Projects'
  }
];

const bottomMenuItems = [
  {
    icon: <ChartIcon />,
    allowedRoles: [ROLES.ADMIN],
    name: 'Reporting'
  },
  {
    icon: <CogIcon />,
    path: '/scheduling/settings/leave-categories',
    allowedRoles: [ROLES.ADMIN],
    name: 'Settings'
  }
];

const SchedulingMenuItems = ({ menuItems, role }) => {
  const location = useLocation();

  if (!menuItems) {
    return <></>;
  }

  return (
    <menu>
      {menuItems
        .filter((item) => item.allowedRoles.includes(role))
        .map(({ path, icon, name }) => (
          <li
            key={name}
            className={getClassNames('nav-menu-item', {
              active:
                name === 'Settings'
                  ? location.pathname.includes('/scheduling/settings')
                  : location.pathname.startsWith(path)
            })}
          >
            <Link to={path}>
              {icon}
              <span>{name}</span>
            </Link>
          </li>
        ))}
    </menu>
  );
};

SchedulingMenuItems.propTypes = {
  menuItems: arrayOf(
    shape({
      icon: oneOfType([element, func]).isRequired,
      path: string.isRequired,
      allowedRoles: arrayOf(string).isRequired,
      name: string.isRequired
    })
  ).isRequired,
  role: string.isRequired
};

export const SchedulingMenu = ({ role }) => (
  <aside className="scheduling-menu">
    <nav className="scheduling-menu__top">
      <SchedulingMenuItems menuItems={topMenuItems} role={role} />
      <hr />
    </nav>
    <nav className="scheduling-menu__bottom">
      <hr />
      <SchedulingMenuItems menuItems={bottomMenuItems} role={role} />
    </nav>
  </aside>
);

SchedulingMenu.defaultProps = {
  role: ''
};

SchedulingMenu.propTypes = {
  role: oneOf([ROLES.ADMIN, ROLES.EMPLOYEE])
};
